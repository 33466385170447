import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import classNames from 'classnames'


const languages = [
  {
    code: 'en',
    name: 'English',
    onselect: 'En',
    country_code: 'gb',
  },
  {
    code: 'zh',
    name: '中国人',
    onselect: '中国人',
    country_code: 'zh',
  },
]

function About() {

const currentLanguageCode = cookies.get('i18next') || 'en'
const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
const { t } = useTranslation()
  return (
    <>
    <div className="ab_section_images_handle" id="about">
    <div className="imageh mob_show">
              <img src="./images/mainhound.svg" className="img-responsive" alt="" />
            </div>
    <div className="mousemove">
        <div className="dh_1 posab c1 mouse" value="2"><img src="/images/blue/1.png" className="img-responsive " /></div>
        <div className="dh_2 posab c2 mouse" value="3"><img src="/images/blue/2.png" className="img-responsive " /></div>
        <div className="dh_2 posab c3 mouse" value="4"><img src="/images/blue/3.png" className="img-responsive " /></div>
        <div className="dh_2 posab c4 mouse" value="3"><img src="/images/blue/4.png" className="img-responsive " /></div>
        <div className="dh_2 posab c5 mouse" value="5"><img src="/images/blue/5.png" className="img-responsive " /></div>
        <div className="dh_2 posab c6 mouse" value="3"><img src="/images/blue/6.png" className="img-responsive " /></div>
        <div className="dh_2 posab c7 mouse" value="3"><img src="/images/blue/7.png" className="img-responsive " /></div>
        <div className="dh_2 posab c8 mouse" value="4"><img src="/images/blue/8.png" className="img-responsive " /></div>
        <div className="dh_2 posab c9 mouse" value="3"><img src="/images/blue/9.png" className="img-responsive " /></div>
        <div className="dh_2 posab c10 mouse" value="3"><img src="/images/blue/10.png" className="img-responsive " /></div>
        <div className="dh_2 posab c11 mouse" value="5"><img src="/images/blue/11.png" className="img-responsive " /></div>
        <div className="dh_2 posab c12 mouse" value="3"><img src="/images/blue/12.png" className="img-responsive " /></div>
        <div className="dh_2 posab c13 mouse" value="3"><img src="/images/blue/13.png" className="img-responsive " /></div>
        <div className="dh_2 posab c14 mouse" value="6"><img src="/images/blue/14.png" className="img-responsive " /></div>
        <div className="dh_2 posab c15 mouse" value="3"><img src="/images/blue/15.png" className="img-responsive " /></div>
      </div>
      <section className="ab_section pad_section text-center d-flex align-items-center">
        <div className="glow"></div>
        <div className="container">
            <div className="text"><h3>{t('abouttitle')} <span>
            {t('aboutdescription')} 
</span></h3></div>
        </div>
      </section>
    </div>
    </>
  );
}

export default About;
