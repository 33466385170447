import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import classNames from 'classnames'

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';



import {
  AiOutlineRight, AiOutlineCloseCircle
} from 'react-icons/ai'
import {
  BiMenuAltRight
} from 'react-icons/bi'
import {
  FaTelegramPlane,
  FaTwitter,
  FaDiscord,
  FaMediumM
} from 'react-icons/fa'
import {
  FcReddit
} from 'react-icons/fc'
import $ from 'jquery';

const languages = [
  {
    code: 'en',
    name: 'English',
    onselect: 'En',
    country_code: 'gb',
  },
  {
    code: 'zh',
    name: '中国人',
    onselect: '中国人',
    country_code: 'zh',
  },
]
function Home() {

$(document).ready(function(){
  $(".navbar-toggler2").click(function(){
    $("body").addClass("menuopen");
  });
  $(".menuClose, .mobile_menu .navbar-nav .nav-link").click(function(){
    $("body").removeClass("menuopen");
  });
});


const currentLanguageCode = cookies.get('i18next') || 'en'
const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
const { t } = useTranslation()



  return (
    <>
    {['lg'].map((expand) => (
        <Navbar key={expand} bg="light" expand={expand} className="mb-3">
          <Container fluid>
            <Navbar.Brand href="#"><img src="./images/logo.svg" alt="" className="img-responsive" width="220px" /></Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Offcanvas
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end align-items-center flex-grow-1 pe-3">
                  <Nav.Link href="https://whitepaper.coinhound.io/" target="_blank">{t('whitepaper')} </Nav.Link>
                  <Nav.Link href="https://drive.google.com/file/d/1grmBPwqsDRrgMBY8dP-smbQUwI6j8XXh/view?usp=share_link" target="_blank">{t('pitchdeck')}</Nav.Link>
                  <Nav.Link href="/#feature">{t('features')}</Nav.Link>
                  <Nav.Link href="/#stake">{t('cndtoken')}</Nav.Link>
                  <Nav.Link href="https://beta.coinhound.io" className="navbtn">{t('dappdemo')}</Nav.Link>
                  <Nav.Link href="https://app.uniswap.org/#/swap?use=V2&inputCurrency=ETH&outputCurrency=0xec505c81d6a7567b5bde804870b1038832fe6da1" className="navbtn bgchange" target='_blank'>{t('buybtn')}</Nav.Link>

          <div className="dropdown">
            <button
              className="btn btn-link navbtn dropdown-toggle slectLan"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              {currentLanguage.onselect}
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              {languages.map(({ code, name, country_code }) => (
                <li key={country_code}>
                  <a
                    href="#"
                    className={classNames('dropdown-item', {
                      selectClass: currentLanguageCode === code,
                    })}
                    onClick={() => {
                      i18next.changeLanguage(code)
                    }}
                  >
                    <span
                    ></span>
                    {name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
                </Nav>

              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}

    <div className="navbar-toggler2"><BiMenuAltRight /></div>
    <div className="mobile_menu">
      <div className="menuClose"><AiOutlineCloseCircle /></div>
      <div className="navbar-nav">
                  <Nav.Link href="https://whitepaper.coinhound.io/" target="_blank">{t('whitepaper')} </Nav.Link>
                  <Nav.Link href="https://drive.google.com/file/d/1grmBPwqsDRrgMBY8dP-smbQUwI6j8XXh/view?usp=share_link" target="_blank">{t('pitchdeck')}</Nav.Link>
                  <Nav.Link href="/#feature">{t('features')}</Nav.Link>
                  <Nav.Link href="/#stake">{t('cndtoken')}</Nav.Link>
                  <Nav.Link href="https://beta.coinhound.io" className="navbtn">{t('dappdemo')}</Nav.Link>
                  <Nav.Link href="https://app.uniswap.org/#/swap?use=V2&inputCurrency=ETH&outputCurrency=0xec505c81d6a7567b5bde804870b1038832fe6da1" className="navbtn bgchange" target='_blank'>{t('buybtn')}</Nav.Link>

          <div className="dropdown">
            <button
              className="btn btn-link navbtn dropdown-toggle slectLan"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              {currentLanguage.onselect}
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              {languages.map(({ code, name, country_code }) => (
                <li key={country_code}>
                  <a
                    href="#"
                    className={classNames('dropdown-item', {
                      selectClass: currentLanguageCode === code,
                    })}
                    onClick={() => {
                      i18next.changeLanguage(code)
                    }}
                  >
                    <span
                    ></span>
                    {name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
                  </div>
    </div>
      <section className="slideSection pad_section text-center">
        <div className="container">
            <h1>{t('slidertitle')}</h1>
            <p><a href="#about" className="a_link">{t('sliderlink')} ></a></p>
            <div className="imageh mob_hide">
              <img src="./images/mainhound.svg" className="img-responsive" alt="" />
            </div>
        </div>
      </section>


    </>
  );
}

export default Home;
