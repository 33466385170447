
import React from 'react';
import Home from './components/home'
import Header from './components/navbar'
import About from './components/about'
import Information from './components/information'
import Features from './components/features'
import Range from './components/range'
import Coming from './components/coming'
import Footer from './components/footer'
import './App.css';

import './responsive.css';


function App() {

  document.addEventListener("mousemove", parallax);
function parallax(event) {
this.querySelectorAll(".mouse").forEach((shift) => {
  const position = shift.getAttribute("value");
  const x = (window.innerWidth - event.pageX * position) / 90;
  const y = (window.innerHeight - event.pageY * position) / 90;

  shift.style.transform = `translateX(${x}px) translateY(${y}px)`;
});
}

  return (
    <>
      <Header />
      <Home />
      <About />
      <Information />
      <Features/>
      <Range />
      <Coming />
      <Footer />
    </>
  );
}

export default App;
