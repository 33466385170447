import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import classNames from 'classnames'


const languages = [
  {
    code: 'en',
    name: 'English',
    onselect: 'En',
    country_code: 'gb',
  },
  {
    code: 'zh',
    name: '中国人',
    onselect: '中国人',
    country_code: 'zh',
  },
]

function Information() {

const currentLanguageCode = cookies.get('i18next') || 'en'
const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
const { t } = useTranslation()
  return (
    <>
      
      <section className="ivnnfo_section pad_section text-left  d-flex align-items-center" id="info">
        <img src="./images/Tracking Hound 1.svg" className="img-responsive right_image"  alt="" />
        <div className="clearboth"></div>
        <div className="container">
          <div className="row">
            <div className="col-sm-5">
             <h3>{t('infotitle')} <span>{t('infosubtitle')}</span></h3>
             <p>{t('infodec')}</p>
           </div>
        </div>
        <div className="borderboxes">
        <div className="row">
          <div className="col-sm-6">
            <div className="borderbox">
              <h4>500,000+</h4>
              <p>{t('infosub1')}</p>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="borderbox">
              <h4>3,500,000+</h4>
              <p>{t('infosub2')}</p>
            </div>
          </div>
      </div>
      </div>
    </div>
      </section>
    </>
  );
}

export default Information;
