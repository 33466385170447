import React, { useState , useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import classNames from 'classnames'

const languages = [
  {
    code: 'en',
    name: 'English',
    onselect: 'En',
    country_code: 'gb',
  },
  {
    code: 'zh',
    name: '中国人',
    onselect: '中国人',
    country_code: 'zh',
  },
]
function Range() { 

const currentLanguageCode = cookies.get('i18next') || 'en'
const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
const { t } = useTranslation()


const [stackValue, setStackValue] = useState(0);
  const [durationValue, setDurationValue] = useState(0);
  const [total, setTotal] = useState();


  const stackArray = [0 , 25 , 50 , 75];
  const durationArray = [0 , 6 , 12];
  const _stackArray = [100 , 1000 , 10000 , 100000];
  const _durationArray = [1 , 6 , 12];

  useEffect(() => {
    if(durationValue === durationArray[0]){
      if(stackValue === stackArray[0]){
        setTotal(2)
      }
      if(stackValue === stackArray[1]){
        setTotal(20)
      }
      if(stackValue === stackArray[2]){
        setTotal(200)
      }
      if(stackValue === stackArray[3]){
        setTotal(2000)
      }
    }

    if(durationValue === durationArray[1]){
      if(stackValue === stackArray[0]){
        setTotal(15)
      }
      if(stackValue === stackArray[1]){
        setTotal(150)
      }
      if(stackValue === stackArray[2]){
        setTotal(1500)
      }
      if(stackValue === stackArray[3]){
        setTotal(15000)
      }
    }

    if(durationValue === durationArray[2]){
      if(stackValue === stackArray[0]){
        setTotal(30)
      }
      if(stackValue === stackArray[1]){
        setTotal(300)
      }
      if(stackValue === stackArray[2]){
        setTotal(3000)
      }
      if(stackValue === stackArray[3]){
        setTotal(30000)
      }
    }

  }, [stackValue , durationValue])   

  return (
    <>
      <section className="range_section ivnnfo_section pad_section text-left  d-flex align-items-center" id="stake">
        <div className="container">
               <h3>{t('titlerange')}<span>
{t('decrange')}</span></h3>
          <div className="m_range">

            <div className="range_box">
            <div className="row d-flex align-items-center">
              <div className="col-sm-8">
                <div className="sn_range snr1">

                    <p className="plabel">{t('CDNstecked')}</p>
                    <div className="in_handle">
                    <input className='line' type="range" step={25} onChange={(e)=>setStackValue(parseFloat(e.target.value))} name="line" value={stackValue} min={0} max={75} />
                    <div className="percentage d-flex text-center">
                    {_stackArray.map((value , key) => <div key={key} className="col in_out"><span className="s2111">{value} CND</span></div>)}
                    </div>

                </div>
              </div>
                <div className="sn_range snr2">
                    <p className="plabel">{t('CDNmonth')}</p>
                    <div className="in_handle">
                    <input className='line' type="range" step={6} onChange={(e)=>setDurationValue(parseFloat(e.target.value))} name="line" value={durationValue} min={0} max={12} />
                    <div className="percentage d-flex text-center">
                      {_durationArray.map((value , key) => <div key={key} className="col in_out"><span className="s2111">{value === 12 ? "1 Year" : `${value} Month`}</span></div>)}
                    </div>
                  </div>
                </div>

            </div>
              <div className="col-sm-4">
                <div className="byte text-center">
                  <img src="./images/dogbyte.png" className="img-responsive" />
                  
                  <h3><div className="valdyan">{total}</div>
<span>{t('perday')}</span></h3>
                </div>
              </div>
              </div>
            </div>
        </div>
            <div className="range_content">
              <p><b>{t('rangesubtitle')}</b>
{t('rangesubdec')}</p>
              <p><a href="https://whitepaper.coinhound.io/ecosystem/treats" target='_blank' className="greencolor"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z"/></svg> {t('rangelink')}</a></p>
            </div>

              </div>
      </section>
    </>
  );
}

export default Range;