import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import classNames from 'classnames'

import Carousel from 'react-bootstrap/Carousel';


const languages = [
  {
    code: 'en',
    name: 'English',
    onselect: 'En',
    country_code: 'gb',
  },
  {
    code: 'zh',
    name: '中国人',
    onselect: '中国人',
    country_code: 'zh',
  },
]

function Features() {

const currentLanguageCode = cookies.get('i18next') || 'en'
const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
const { t } = useTranslation()
  return (
  <section className="featuresSection pad_section text-center d-flex align-items-center" id="feature">
  <div className="container">
          <h4>{t('featuresseaction')}</h4>
    <Carousel variant="dark">
      <Carousel.Item>
        <img
          src="./images/wallet.svg"
          alt="First slide" className="img-responsive"
        />
          <h3>
          {t('slititle1')}<span>
          {t('slides1')}</span></h3>
      </Carousel.Item>
      <Carousel.Item>
        <img
          
          src="./images/slide2.svg"
          alt="First slide" className="img-responsive"
        />
          <h3>
          {t('slititle2')}<span>
          {t('slides2')}</span></h3>
      </Carousel.Item>
      <Carousel.Item>
        <img
          
          src="./images/slide3.svg"
          alt="First slide" className="img-responsive"
        />
          <h3>
          {t('slititle3')}<span>
          {t('slides3')}</span></h3>
      </Carousel.Item>
      <Carousel.Item>
        <img
          
          src="./images/slide4.svg"
          alt="First slide" className="img-responsive"
        />
          
          <h3>
          {t('slititle4')}<span>
          {t('slides4')}</span></h3>
      </Carousel.Item>
    </Carousel>
    </div>
    </section>
  );
}

export default Features;