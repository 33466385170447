import React, { useState , useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import classNames from 'classnames'

const languages = [
  {
    code: 'en',
    name: 'English',
    onselect: 'En',
    country_code: 'gb',
  },
  {
    code: 'zh',
    name: '中国人',
    onselect: '中国人',
    country_code: 'zh',
  },
]

function Coming() {
const currentLanguageCode = cookies.get('i18next') || 'en'
const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
const { t } = useTranslation()
  return (
    <>
      
      <section className="comingSection pad_section text-left d-flex align-items-center">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-sm-5">
              <h3>{t('comingMaintitle')}<span>
{t('comingMaindes')}</span></h3>
  <p><a href="https://drive.google.com/file/d/1LLa61XKniiehG1Tg5GrE1vpYu45AZJxS/view" target='_blank' className="greencolor"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z"/></svg>{t('comingMainlink')}</a></p>
            </div>
            <div className="col-sm-7">
                <div className="row boxs">
                  <div className="col-sm-6">
                    <div className="box_bg">
                      <h4>{t('comingtitle1')}</h4>
                      <p>{t('comingdes1')}</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="box_bg">
                      <h4>{t('comingtitle2')}</h4>
                      <p>{t('comingdes2')}</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="box_bg">
                      <h4>{t('comingtitle3')}</h4>
                      <p>{t('comingdes3')}</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="box_bg">
                      <h4>{t('comingtitle4')}</h4>
                      <p>{t('comingdes4')}</p>
                    </div>
                  </div>
                  <div className="pos_img">
                  <img src="./images/hound.png" className="img-responsive " alt="" />
                </div>
                </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}

export default Coming;
